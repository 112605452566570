import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Hub from "./hub"

const GetInspired = ({ data }) => {
  const articles = data.articles.nodes.reduce((r, a) => {
    r[a.data.country] = r[a.data.country] || []
    r[a.data.country].push(a)
    return r
  }, {})
  const hub = data.hub.nodes[0].data
  const groups = Object.keys(articles)
    .map((k) => {
      return { label: k, guides: articles[k] }
    })
    .sort((a, b) => b.guides.length - a.guides.length)
  return <Hub hub={hub} groups={groups} />
}

export const query = graphql`
  query InspirationHubQuery {
    hub: allPrismicArticle(filter: { data: { articletype: { eq: "Inspiration Hub" } } }) {
      nodes {
        data {
          title
          heroimage {
            gatsbyImageData(height: 700)
          }
          body {
            ... on PrismicArticleDataBodyRichText {
              id
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
            ... on PrismicArticleDataBodyImage {
              id
              slice_type
              primary {
                caption
                image {
                  gatsbyImageData(width: 1280, height: 500)
                }
              }
            }
          }
        }
      }
    }
    articles: allPrismicArticle(
      filter: { tags: { in: ["inspiration"] } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        uid
        url
        data {
          country
          title
          previewtext
          author {
            document {
              ... on PrismicAuthor {
                url
                data {
                  name
                }
                first_publication_date
              }
            }
          }
          heroimage {
            gatsbyImageData(height: 700)
          }
        }
        first_publication_date(formatString: "DD MMMM YYYY")
      }
    }
  }
`

GetInspired.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default GetInspired
